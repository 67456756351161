import React from 'react';
import { PuffLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/variables';

const useStyles = makeStyles({
	loading: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const Loader: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.loading}>
			<PuffLoader color={colors.white_main} />
		</div>
	);
};

export default Loader;
