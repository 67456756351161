import React from 'react';
import { IIcon } from '..';
import { colors } from '../../../styles/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
	{
		root: { color: colors.twitch },
	},
	{ name: 'TwitchIcon' }
);

export const TwitchIcon: React.FC<IIcon> = (props) => {
	const { className, link } = props;
	const classes = useStyles();
	return (
		<>
			{link ? (
				<a
					target="_blank"
					rel="noreferrer"
					href={link}
					aria-label="Twitch Icon"
				>
					<FontAwesomeIcon
						className={`${classes.root} ${className}`}
						icon={faTwitch}
					/>
				</a>
			) : (
				<FontAwesomeIcon
					className={`${classes.root} ${className}`}
					icon={faTwitch}
				/>
			)}
		</>
	);
};
