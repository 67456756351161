import { makeStyles } from "@material-ui/core";
import React from "react";
import { PageTitle } from "../components/PageTitle";
import { SocialsBox } from "../components/SocialsBox";

const useStyles = makeStyles(
  ({ spacing, breakpoints }) => ({
    inline: {
      display: "flex",
      margin: spacing(0, -2),
      "& > *": {
        margin: spacing(0, 2),
        flex: 1,
      },
      [breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
  }),
  { name: "Socials" }
);

export const Socials: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <PageTitle title={"Socials"} />
      <SocialsBox
        title="Twitch"
        link="https://www.twitch.tv/xhankyy"
        platform="Twitch"
      />
      <SocialsBox
        title="Discord"
        link="https://discord.com/invite/E4YyaWx"
        platform="Discord"
      />
      <SocialsBox
        title="Twitter"
        link="https://twitter.com/xHankyy"
        platform="Twitter"
      />
      <div className={classes.inline}>
        <SocialsBox
          title="Youtube"
          link="https://www.youtube.com/channel/UCG_DhLLEMY5QCEApUpd2SGg"
          platform="Youtube"
        />
        <SocialsBox
          title="2. Kanal"
          link="https://www.youtube.com/channel/UC4VaGeclAp58vh77MiOhWEQ"
          platform="Youtube"
        />
      </div>
      <SocialsBox
        title="Instagram"
        link="https://www.instagram.com/xhankyy/"
        platform="Instagram"
      />
    </div>
  );
};
