import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../styles/variables';

const useStyles = makeStyles(
	({ spacing }) => ({
		root: {
			marginTop: spacing(12),
			padding: spacing(4, 4),
			maxWidth: 1440,
			minHeight: `calc(100vh - ${spacing(19.5)}px)`,
			margin: 'auto',
		},
		gap: {
			marginBottom: spacing(1),
		},
		heading: {
			marginBottom: spacing(2),
		},
		text: {
			marginBottom: spacing(3),
		},
		link: {
			color: colors.turquoise_light,
		},
	}),
	{ name: 'PrivacyPolicy' }
);

export const PrivacyPolicy: React.FC = () => {
	const classes = useStyles();
	return (
		<>
			<Typography variant="h3" component="h1" className={classes.heading}>
				Datenschutzerklärung
			</Typography>
			<Typography className={classes.gap}>
				Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
				EU-Datenschutzgrundverordnung (DSGVO), ist:
			</Typography>
			<Typography>Nis von Kalben </Typography>
			<Typography>Gärtnerweg 53 25436 Tornesch, Deutschland</Typography>
			<Typography>Vertretungsberechtigte Personen: Geschäftsführung</Typography>
			<Typography>
				E-Mail-Adresse:{' '}
				<a
					className={classes.link}
					href="mailto:l_rustemeier@web.de?subject=xHankyy.de%3A+"
				>
					l_rustemeier@web.de
				</a>
			</Typography>
			<Typography>
				Telefon:{' '}
				<a className={classes.link} href="tel:+494122507671">
					+494122507671
				</a>
			</Typography>
			<Typography className={classes.text}>
				Impressum: www.chelepelu.com/impressum
			</Typography>
			<Typography variant="h4" component="h2" className={classes.heading}>
				Ihre Betroffenenrechte
			</Typography>
			<Typography>
				Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
				können Sie jederzeit folgende Rechte ausüben:
			</Typography>
			<ul>
				<li>
					Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
					(Art. 15 DSGVO),
				</li>
				<li>
					Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
				</li>
				<li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
				<li>
					Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
					gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
				</li>
				<li>
					Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
					und
				</li>
				<li>
					Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
					haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
				</li>
			</ul>
			<Typography>
				Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
				jederzeit mit Wirkung für die Zukunft widerrufen.
			</Typography>
			<Typography>
				Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
				wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
				Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
				Behörde.
			</Typography>
			<Typography className={classes.text}>
				Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
				Anschrift finden Sie unter:{' '}
				<a
					className={classes.link}
					href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
					target="_blank"
					rel="nofollow noopener noreferrer"
				>
					https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
				</a>
				.
			</Typography>
			<Typography variant="h4" component="h2" className={classes.heading}>
				Erfassung allgemeiner Informationen beim Besuch unserer Website
			</Typography>
			<Typography variant="h5" component="h3" className={classes.gap}>
				Art und Zweck der Verarbeitung:
			</Typography>
			<Typography>
				Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
				registrieren oder anderweitig Informationen übermitteln, werden
				automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
				(Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das
				verwendete Betriebssystem, den Domainnamen Ihres
				Internet-Service-Providers, Ihre IP-Adresse und ähnliches.{' '}
			</Typography>
			<Typography>
				Sie werden insbesondere zu folgenden Zwecken verarbeitet:
			</Typography>
			<ul>
				<li>
					Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
				</li>
				<li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
				<li>Auswertung der Systemsicherheit und -stabilität sowie</li>
				<li>zur Optimierung unserer Website.</li>
			</ul>
			<Typography className={classes.text}>
				Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
				ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert
				statistisch ausgewertet, um unseren Internetauftritt und die
				dahinterstehende Technik zu optimieren.
			</Typography>
			<Typography variant="h5" component="h3" className={classes.gap}>
				Rechtsgrundlage und berechtigtes Interesse:
			</Typography>
			<Typography className={classes.text}>
				Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
				unseres berechtigten Interesses an der Verbesserung der Stabilität und
				Funktionalität unserer Website.
			</Typography>
			<Typography variant="h5" component="h3" className={classes.gap}>
				Empfänger:
			</Typography>
			<Typography className={classes.text}>
				Empfänger der Daten sind ggf. technische Dienstleister, die für den
				Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig
				werden.
			</Typography>
			<Typography variant="h5" component="h3" className={classes.gap}>
				Speicherdauer:
			</Typography>
			<Typography>
				Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht
				mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung
				der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung
				beendet ist.
			</Typography>
			<Typography className={classes.text}>
				Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens
				14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In
				diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine
				Zuordnung des aufrufenden Clients nicht mehr möglich ist.
			</Typography>
			<Typography variant="h5" component="h3" className={classes.gap}>
				Bereitstellung vorgeschrieben oder erforderlich:
			</Typography>
			<Typography className={classes.text}>
				Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
				gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist
				jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
				gewährleistet. Zudem können einzelne Dienste und Services nicht
				verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch
				ausgeschlossen.
			</Typography>
			<Typography variant="h4" component="h2" className={classes.heading}>
				SSL-Verschlüsselung
			</Typography>
			<Typography className={classes.text}>
				Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
				wir dem aktuellen Stand der Technik entsprechende
				Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
			</Typography>
			<Typography variant="h4" component="h2" className={classes.heading}>
				Cookies
			</Typography>
			<Typography className={classes.text}>
				Auf dieser Seite werden so genannte "Session-Cookies" verwendet. Sie
				werden nach Ende Ihres Besuchs automatisch gelöscht.
			</Typography>
			<hr className={classes.text} />
			<Typography variant="h4" component="h2" className={classes.heading}>
				Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO
			</Typography>
			<Typography variant="h5" component="h3" className={classes.gap}>
				Einzelfallbezogenes Widerspruchsrecht
			</Typography>
			<Typography>
				Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
				Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
				personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
				(Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt,
				Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung
				gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
			</Typography>
			<Typography className={classes.text}>
				Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht
				mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe
				für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
				Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
				Ausübung oder Verteidigung von Rechtsansprüchen.
			</Typography>
			<Typography variant="h5" component="h3" className={classes.gap}>
				Empfänger eines Widerspruchs
			</Typography>
			<Typography className={classes.text}>
				<a
					className={classes.link}
					href="mailto:l_rustemeier@web.de?subject=xHankyy.de%3A+"
				>
					l_rustemeier@web.de
				</a>
			</Typography>
			<hr className={classes.text} />
			<Typography variant="h4" component="h2" className={classes.heading}>
				Änderung unserer Datenschutzbestimmungen
			</Typography>
			<Typography className={classes.text}>
				Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
				stets den aktuellen rechtlichen Anforderungen entspricht oder um
				Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
				z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
				dann die neue Datenschutzerklärung.
			</Typography>
			<Typography variant="h4" component="h2" className={classes.heading}>
				Fragen an den Datenschutzbeauftragten
			</Typography>
			<Typography>
				Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
				E-Mail oder wenden Sie sich direkt an die für den Datenschutz
				verantwortliche Person in unserer Organisation:
			</Typography>
			<Typography>
				<a href="mailto:l_rustemeier@web.de?subject=xHankyy.de%3A+">
					l_rustemeier@web.de
				</a>
			</Typography>
			<Typography className={classes.text}>
				<em>
					Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt,
					den Experten für{' '}
					<a
						className={classes.link}
						href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
						target="_blank"
						rel="noopener noreferrer"
					>
						externe Datenschutzbeauftragte
					</a>{' '}
					(Version #2020-09-30).
				</em>
			</Typography>
		</>
	);
};
