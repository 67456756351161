import React from "react";
import { colors } from "../../../styles/variables";
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  DiscordIcon,
  TwitterIcon,
  YoutubeIcon,
  InstagramIcon,
  TwitchIcon,
} from "../../Icons";

const navItems = [
  {
    path: "/impressum",
    label: "Impressum",
  },
  {
    path: "/datenschutz",
    label: "Datenschutz",
  },
];

const useStyles = makeStyles(
  ({ spacing, breakpoints }) => ({
    root: {
      background: colors.black_light,
      borderTop: `1px solid ${colors.turquoise_main}`,
    },
    content: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 1440,
      margin: "auto",
      padding: spacing(2, 4),
    },
    credits: {
      position: "relative",
      color: colors.turquoise_light,
      [breakpoints.up("sm")]: {
        "&:before": {
          content: "'Made by'",
          position: "absolute",
          top: -6,
          left: 0,
          fontSize: 8,
          textTransform: "uppercase",
          color: colors.white_dark,
        },
      },
    },
    iconContainer: {
      "& > * + *": {
        marginLeft: spacing(2),
      },
    },
    icon: {
      height: "24px !important",
      width: "24px !important",
      "&:hover": {
        cursor: "pointer",
      },
    },
    subBar: {
      maxWidth: 1440,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      borderTop: `1px solid ${colors.gray_dark}`,
      "& > *": {
        margin: spacing(0, 1.5),
      },
    },
  }),
  { name: "Footer" }
);

export const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        <div>
          <Typography
            className={classes.credits}
            variant="body2"
            component="a"
            href="mailto:plugo.business@gmail.com"
          >
            plugo_
          </Typography>
        </div>
        <div className={classes.iconContainer}>
          <TwitchIcon
            className={classes.icon}
            link="https://www.twitch.tv/xhankyy/"
          />
          <DiscordIcon
            className={classes.icon}
            link="https://discord.com/invite/E4YyaWx"
          />
          <TwitterIcon
            className={classes.icon}
            link="https://twitter.com/xHankyy"
          />
          <YoutubeIcon
            className={classes.icon}
            link="https://www.youtube.com/channel/UCG_DhLLEMY5QCEApUpd2SGg"
          />
          <InstagramIcon
            className={classes.icon}
            link="https://www.instagram.com/xhankyy/"
          />
        </div>
      </div>
      <div className={classes.subBar}>
        {navItems.map((item) => {
          const { path, label } = item;
          return (
            <Link to={path} key={path}>
              <Typography variant="overline" component="span">
                {label}
              </Typography>
            </Link>
          );
        })}
      </div>
    </footer>
  );
};
