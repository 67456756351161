const url = process.env.REACT_APP_API_URL + 'getloadouts' || '';

export interface ILoadout {
	name: string;
	link: string;
	preview: string;
	filter: string;
	yt: string;
}

interface ILoadouts {
	loadouts: ILoadout[];
}

export const fetchLoadouts = async (): Promise<ILoadouts> =>
	await fetch(url, { method: 'GET' })
		.then((res) => res.json())
		.catch((err) => console.log(err));

export const fetchLoadoutById = async (id: string): Promise<ILoadout> =>
	await fetch(url + '/' + id, { method: 'GET' })
		.then((res) => res.json())
		.catch((err) => console.log(err));
