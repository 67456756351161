import React from 'react';
import { LoadoutContainer } from '../components/LoadoutContainer';
import { PageTitle } from '../components/PageTitle';

export const Loadouts: React.FC = () => {
	return (
		<>
			<PageTitle title={'Loadouts'} />
			<LoadoutContainer />
		</>
	);
};
