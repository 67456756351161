import React from 'react';
import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { colors } from '../../../styles/variables';
import { NavItems } from './NavItems';

const useStyles = makeStyles(
	({ spacing, breakpoints }) => ({
		root: {
			paddingTop: spacing(1),
			paddingBottom: spacing(1),
			borderBottom: `1px solid ${colors.turquoise_main}`,
			[breakpoints.down('xs')]: {
				paddingTop: spacing(0.5),
				paddingBottom: spacing(0.5),
			},
		},
		icon: {
			width: 72,
			height: 72,
		},
	}),
	{ name: 'Header' }
);

export const Header: React.FC = () => {
	const classes = useStyles();

	return (
		<AppBar className={classes.root} elevation={0}>
			<Toolbar>
				<Link to="/">
					<img
						src="/hankyy-logo.png"
						className={classes.icon}
						alt="hanky logo"
					/>
				</Link>
				<NavItems />
			</Toolbar>
		</AppBar>
	);
};
