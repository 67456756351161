import { colors } from './variables';
import { createTheme, Theme } from '@material-ui/core';

export const defaultTheme: Theme = createTheme({
	overrides: {
		MuiTypography: {
			h4: {
				fontSize: '1.8rem',
			},
			overline: {
				color: colors.white_dark,
			},
		},
		MuiCssBaseline: {
			'@global': {
				'#root': {
					minHeight: '100vh',
					display: 'flex',
					flexDirection: 'column',
				},
				body: {
					backgroundColor: colors.gray_dark,
					color: colors.white_main,
				},
				a: {
					textDecoration: 'none',
					color: 'inherit',
				},
			},
		},
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: colors.black_light,
			},
		},
		MuiListItem: {
			root: {
				paddingTop: 5,
				paddingBottom: 5,
			},
		},
		MuiInputBase: {
			root: {
				backgroundColor: colors.gray_light,
				color: colors.black_dark,
			},
		},
		MuiOutlinedInput: {
			root: {
				padding: 0,
			},
			input: {
				padding: '12px 16px',
			},
		},
	},
});
