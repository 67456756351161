import React from "react";
import { borderRadius, colors } from "../../styles/variables";
import { makeStyles, Typography } from "@material-ui/core";
import { DiscordIcon } from "../Icons/Discord";
import { TwitterIcon } from "../Icons/Twitter";
import { YoutubeIcon } from "../Icons/Youtube";
import { InstagramIcon } from "../Icons/Instagram";
import { TwitchIcon } from "../Icons/Twitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(
  ({ spacing }) => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: spacing(4),
      padding: spacing(2),
      borderRadius: borderRadius * 2,
      border: `3px solid ${colors.white_main}`,
      transition: "box-shadow 300ms ease",
      "& > * + *": {
        marginLeft: spacing(3),
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
    small: {
      padding: spacing(0.5, 1),
      borderWidth: 1,
      "& > * + *": {
        marginLeft: spacing(1),
      },
    },
    twitch: {
      backgroundColor: colors.twitch,
      "&:hover": {
        boxShadow: `0px 1px 5px 2px ${colors.twitch}`,
      },
    },
    discord: {
      backgroundColor: colors.discord,
      "&:hover": {
        boxShadow: `0px 1px 5px 2px ${colors.discord}`,
      },
    },
    twitter: {
      backgroundColor: colors.twitter,
      "&:hover": {
        boxShadow: `0px 1px 5px 2px ${colors.twitter}`,
      },
    },
    youtube: {
      backgroundColor: colors.youtube,
      "&:hover": {
        boxShadow: `0px 1px 5px 1px ${colors.youtube}`,
      },
    },
    instagram: {
      background:
        "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
      "&:hover": {
        boxShadow: `0px 1px 5px 1px #dc2743`,
      },
    },
    xhankyy: {
      backgroundColor: colors.turquoise_main,
      "&:hover": {
        boxShadow: `0px 1px 5px 1px ${colors.turquoise_main}`,
      },
    },
    icon: {
      height: "40px !important",
      width: "40px !important",
      color: `${colors.white_main} !important`,
    },
  }),
  { name: "SocialsBox" }
);

interface ISocialsBox {
  title?: string;
  link: string;
  platform: string;
  small?: boolean;
}

export const SocialsBox: React.FC<ISocialsBox> = (props) => {
  const { title, link, platform, small = false } = props;
  const classes = useStyles();

  const getClasses = (platform: string) => {
    switch (platform.toLocaleLowerCase()) {
      case "twitch":
        return classes.twitch;
      case "discord":
        return classes.discord;
      case "twitter":
        return classes.twitter;
      case "youtube":
        return classes.youtube;
      case "instagram":
        return classes.instagram;
      default:
        return classes.xhankyy;
    }
  };

  const getIcon = (platform: string) => {
    switch (platform.toLocaleLowerCase()) {
      case "twitch":
        return <TwitchIcon className={classes.icon} />;
      case "discord":
        return <DiscordIcon className={classes.icon} />;
      case "twitter":
        return <TwitterIcon className={classes.icon} />;
      case "youtube":
        return <YoutubeIcon className={classes.icon} />;
      case "instagram":
        return <InstagramIcon className={classes.icon} />;
      default:
        return (
          <FontAwesomeIcon className={classes.icon} icon={faShoppingCart} />
        );
    }
  };

  return (
    <div>
      <a target="_blank" rel="noreferrer" href={link}>
        <div
          className={`${classes.root} ${getClasses(platform)} ${
            small ? classes.small : ""
          }`}
        >
          {getIcon(platform)}
          {title && (
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          )}
        </div>
      </a>
    </div>
  );
};
