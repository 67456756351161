import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Loader from '../Loader';
import { LoadoutBox } from '../LoadoutBox';
import { fetchLoadouts, ILoadout } from '../../api/loadouts';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../styles/variables';

const useStyles = makeStyles(
	({ spacing }) => ({
		adornment: {
			marginRight: spacing(1.5),
			paddingRight: spacing(1.5),
			borderRight: `1px solid ${colors.gray_dark}`,
		},
		search: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: spacing(4),
		},
		icon: {
			marginLeft: spacing(1.5),
			width: '20px !important',
			height: '20px !important',
			color: colors.black_light,
		},
		checkboxContainer: {
			textAlign: 'center',
		},
		checkbox: {
			color: `${colors.turquoise_light} !important`,
		},
	}),
	{ name: 'LoadoutContainer' }
);

export const LoadoutContainer: React.FC = () => {
	const [loadouts, setLoadouts] = useState<ILoadout[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [options, setOptions] = useState<string[]>([]);
	const [filter, setFilter] = useState<string[]>([]);
	const loadoutRef = useRef<ILoadout[]>([]);
	const classes = useStyles();

	useEffect(() => {
		let mounted = true;
		const getLoadouts = async () => {
			mounted && setLoading(true);
			await fetchLoadouts()
				.then((res) => {
					loadoutRef.current = res.loadouts;
					const filterOptions: string[] = [];
					res.loadouts.map(
						(loadout) =>
							!filterOptions.includes(loadout.filter) &&
							filterOptions.push(loadout.filter)
					);
					mounted && setOptions(filterOptions);
					mounted && setFilter(filterOptions);
					mounted && setLoadouts(res.loadouts);
					mounted && setLoading(false);
				})
				.catch(() => mounted && setLoading(false));
		};
		getLoadouts();
		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		let mounted = true;
		mounted &&
			setLoadouts(
				loadoutRef.current.filter((loadout) => filter.includes(loadout.filter))
			);
		return () => {
			mounted = false;
		};
	}, [filter]);

	const handleChange = (
		e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		e.preventDefault();
		setLoadouts(
			loadoutRef.current.filter(
				(loadout) =>
					filter.includes(loadout.filter) &&
					loadout.name
						.toLocaleLowerCase()
						.includes(e.target.value.toLocaleLowerCase())
			)
		);
	};

	const handleFilter = (e: any) => {
		setFilter((f) =>
			e.target.checked
				? [...f, e.target.value]
				: [...f.filter((opt) => opt !== e.target.value)]
		);
	};

	const mapLoadouts = () =>
		loadouts.length > 0 ? (
			loadouts.map((loadout: ILoadout, key: number) => (
				<Grid item xs={12} sm={6} md={4} key={key}>
					<LoadoutBox {...loadout} />
				</Grid>
			))
		) : (
			<Grid item xs={12}>
				<Typography variant="h5" align="center" component="p">
					Es konnte kein passendes Loadout gefunden werden.
				</Typography>
			</Grid>
		);

	return (
		<>
			<Grid container className={classes.search} spacing={3}>
				<Grid item xs={12} md={3}>
					<TextField
						variant="outlined"
						placeholder="Suche..."
						onChange={handleChange}
						focused={false}
						InputProps={{
							startAdornment: (
								<Typography
									variant="body1"
									component="p"
									className={classes.adornment}
								>
									Loadout
								</Typography>
							),
							endAdornment: (
								<FontAwesomeIcon className={classes.icon} icon={faSearch} />
							),
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={9}>
					<Grid container justifyContent="center">
						{options.map((option) => (
							<Grid
								item
								xs={6}
								md={2}
								key={option}
								className={classes.checkboxContainer}
							>
								<FormControlLabel
									control={
										<Checkbox
											value={option}
											checked={filter.includes(option)}
											onChange={handleFilter}
											className={classes.checkbox}
										/>
									}
									label={option}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
			{loading ? (
				<Loader />
			) : (
				<Grid container spacing={4}>
					{mapLoadouts()}
				</Grid>
			)}
		</>
	);
};
