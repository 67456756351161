import React from "react";
import { colors } from "../../styles/variables";
import { Divider, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(
  ({ spacing }) => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    divider: {
      margin: spacing(2, 0, 4),
      backgroundColor: colors.turquoise_main,
    },
  }),
  { name: "Loadouts" }
);

interface IPageTitle {
  title: string;
  endIcon?: JSX.Element;
}

export const PageTitle: React.FC<IPageTitle> = (props) => {
  const { title, endIcon } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h3" component="h1">
          {title}
        </Typography>
        {!!endIcon && endIcon}
      </div>
      <Divider className={classes.divider} />
    </>
  );
};
