import React, { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { Dialog, makeStyles, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { borderRadius, colors } from '../../../styles/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(
	({ spacing, breakpoints }) => ({
		root: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly',
			[breakpoints.down('xs')]: {
				justifyContent: 'flex-end',
			},
		},
		navItem: {
			padding: spacing(1),
			position: 'relative',
			width: 'max-content',
			'&:after': {
				content: "''",
				position: 'absolute',
				bottom: 0,
				left: 0 + spacing(1),
				width: `calc(100% - ${spacing(2)}px)`,
				height: 3,
				background: colors.turquoise_main,
				borderRadius: borderRadius,
				transform: 'scaleX(0)',
				transition: 'transform 300ms ease',
			},
			'&:hover': {
				'&:after': { transform: 'scaleX(1)' },
			},
		},
		active: {
			color: colors.turquoise_light,
		},
		icon: {
			width: '30px !important',
			height: '30px !important',
			marginRight: spacing(2),
		},
		modal: {
			margin: 'auto',
			position: 'absolute',
		},
		navModal: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			color: colors.white_main,
			backgroundColor: colors.gray_dark,
			borderRadius: borderRadius,
			border: `1px solid ${colors.turquoise_main}`,
			'& > * ': {
				padding: spacing(2),
				textAlign: 'center',
			},
			'& > * + *': {
				borderTop: `1px solid ${colors.black_dark}`,
			},
		},
	}),
	{ name: 'NavItems' }
);

const navItems = [
	{
		path: '/loadouts',
		label: 'Loadouts',
	},
	{
		path: '/merch',
		label: 'Merch',
	},
	{
		path: '/killduell',
		label: 'Killduell',
	},
	{
		path: '/socials',
		label: 'Socials',
	},
];

const useLayout = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const NavItems: React.FC = () => {
	const { pathname } = useLocation();
	const [responsive, setResponsive] = useState<boolean>(
		window.innerWidth < 600
	);
	const [open, setOpen] = useState<boolean>(false);
	const responsiveRef = useRef<boolean>(window.innerWidth < 600);
	const classes = useStyles();

	useLayout(() => {
		let mounted = true;
		if (typeof window === 'undefined') return;
		const handleResize = (e: any) => {
			if (e.target.innerWidth < 600 && !responsiveRef.current) {
				responsiveRef.current = !responsiveRef.current;
				mounted && setResponsive((r) => !r);
			} else if (e.target.innerWidth >= 600 && responsiveRef.current) {
				responsiveRef.current = !responsiveRef.current;
				mounted && setResponsive((r) => !r);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => {
			mounted = false;
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const currentLink = (path: string) => pathname.includes(path);

	const handleOpen = () => setOpen((o) => !o);

	return (
		<div className={classes.root}>
			{responsive ? (
				<>
					<FontAwesomeIcon
						icon={faBars}
						className={classes.icon}
						onClick={handleOpen}
					/>
					<Dialog
						className={classes.modal}
						open={open}
						onClose={handleOpen}
						closeAfterTransition
						fullWidth
					>
						<div className={classes.navModal}>
							{navItems.map((item) => {
								const { path, label } = item;
								return (
									<Link to={path} key={path} onClick={handleOpen}>
										<Typography
											className={`${classes.navItem} ${
												currentLink(path) ? classes.active : ''
											}`}
											variant="h6"
											component="span"
										>
											{label}
										</Typography>
									</Link>
								);
							})}
						</div>
					</Dialog>
				</>
			) : (
				<>
					{navItems.map((item) => {
						const { path, label } = item;
						return (
							<Link to={path} key={path}>
								<Typography
									className={`${classes.navItem} ${
										currentLink(path) ? classes.active : ''
									}`}
									variant="h6"
									component="span"
								>
									{label}
								</Typography>
							</Link>
						);
					})}
				</>
			)}
		</div>
	);
};
