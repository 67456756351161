import React from 'react';
import { ILoadout } from '../../api/loadouts';
import { borderRadius, colors } from '../../styles/variables';
import { makeStyles, Typography } from '@material-ui/core';
import { YoutubeIcon } from '../Icons/Youtube';

const useStyles = makeStyles(
	({ spacing }) => ({
		root: {
			position: 'relative',
			width: '100%',
			height: '100%',
			padding: spacing(2),
			backgroundColor: colors.gray_dark,
			border: `1px solid ${colors.gray_main}`,
			borderRadius: borderRadius * 2,
			overflow: 'hidden',
			transition: 'transform 500ms ease, box-shadow 300ms ease',
			'&:hover': {
				boxShadow: `0px 1px 5px 2px ${colors.turquoise_light}`,
				transform: 'scale(1.03)',
			},
		},
		image: {
			width: `calc(100% + ${spacing(4)}px)`,
			height: `calc(100% + ${spacing(2)})`,
			objectFit: 'fill',
			margin: spacing(-2),
		},
		filter: {
			'&:after': {
				content: "''",
				position: 'absolute',
				bottom: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: borderRadius * 2,
				background:
					'radial-gradient(circle, rgba(0, 0, 0,0.5474790599833684) 0%, rgba(10,10,10,0.5) 100%)',
			},
		},
		label: {
			position: 'absolute',
			top: 10,
			left: '3%',
			width: '100%',
		},
		icon: {
			position: 'absolute',
			top: 5,
			right: '3%',
		},
		youtube: {
			height: '40px !important',
			width: '40px !important',
		},
		none: {
			height: '40px !important',
			width: '40px !important',
			color: colors.gray_main,
		},
		button: {
			position: 'absolute',
			left: 0,
			bottom: 0,
			width: '100%',
			backgroundColor: colors.gray_dark,
			padding: spacing(1.5),
			borderTop: `1px solid ${colors.gray_main}`,
			textTransform: 'uppercase',
		},
	}),
	{ name: 'LoadoutBox' }
);

export const LoadoutBox: React.FC<ILoadout> = (props) => {
	const { name, preview, yt, link } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.filter}>
				<img className={classes.image} src={preview} alt={name} />
			</div>
			<div className={classes.label}>
				<Typography variant="h4" component="h2">
					{name}
				</Typography>
			</div>
			<Typography
				className={classes.icon}
				component={yt ? 'a' : 'div'}
				href={yt && yt}
				aria-label={yt ? 'Icon mit Verlinkung auf Ladout-Guide' : ''}
			>
				<YoutubeIcon className={yt ? classes.youtube : classes.none} />
			</Typography>
			<Typography
				className={classes.button}
				variant="h6"
				component="a"
				align="center"
				href={link}
			>
				Ansehen
			</Typography>
		</div>
	);
};
