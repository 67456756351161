import React from "react";
import { PageTitle } from "../components/PageTitle";
import { Grid, makeStyles } from "@material-ui/core";
import { SocialsBox } from "../components/SocialsBox";

const useStyles = makeStyles(
  ({ spacing, breakpoints }) => ({
    iframeContainer: {
      position: "relative",
      height: `calc(100vh - ${spacing(41)}px)`,
      "& > *": {
        border: 0,
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
      },
      [breakpoints.down("xs")]: {
        height: `calc(100vh - ${spacing(38.6)}px)`,
      },
    },
  }),
  { name: "Merch" }
);

export const Merch: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <PageTitle title={"Merch"} />
      <Grid container justify="center">
        <Grid item xs={12} sm={6} md={4}>
          <SocialsBox
            title="Zum Shop"
            link="https://www.chelepelu.shop/xhankyy"
            platform="xhankyy"
            small
          />
        </Grid>
      </Grid>
      <div className={classes.iframeContainer}>
        <iframe src="https://www.chelepelu.shop/xhankyy" title="Merch" />
      </div>
    </>
  );
};
