import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { colors } from "../styles/variables";

const useStyles = makeStyles(
  ({ spacing }) => ({
    root: {
      marginTop: spacing(12),
      padding: spacing(4, 4),
      maxWidth: 1440,
      minHeight: `calc(100vh - ${spacing(19.5)}px)`,
      margin: "auto",
    },
    heading: {
      marginBottom: spacing(2),
    },
    text: {
      marginBottom: spacing(3),
    },
    link: {
      color: colors.turquoise_light,
    },
  }),
  { name: "Impressum" }
);

export const Impressum: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3" component="h1" className={classes.heading}>
        Impressum
      </Typography>
      <Typography variant="h4" component="h2" className={classes.heading}>
        Anschrift und Telefon
      </Typography>
      <Typography>
        Chelepelu UG (haftungsbeschränkt) Gärtnerweg 53 25436 Tornesch
      </Typography>
      <Typography>
        <Typography>
          Tel:{" "}
          <a className={classes.link} href="tel:+49 4122 50 767 70">
            +49 4122 50 767 70
          </a>
        </Typography>
        <Typography>
          Fax:{" "}
          <a className={classes.link} href="fax:+49 4122 50 767 80">
            +49 4122 50 767 80
          </a>
        </Typography>
        <Typography className={classes.text}>
          E-Mail:
          <a
            className={classes.link}
            href="mailto:l_rustemeier@web.de?subject=xHankyy.de%3A+"
          >
            l_rustemeier@web.de
          </a>
        </Typography>
      </Typography>
      <Typography variant="h4" component="h2" className={classes.heading}>
        Firmendaten
      </Typography>
      <Typography>
        <Typography>
          Name: Chelepelu UG Geschäftsführung: Nis von Kalben
        </Typography>
        <Typography>
          Handelsregisternummer: HRB-Nr.: 15291 PI Registergericht: Amtsgericht
        </Typography>
        <Typography>Pinneberg USt-ID: DE335602021</Typography>
      </Typography>
      <Typography>Bankverbindung: PENTA (solarisBank AG) </Typography>
      <Typography>IBAN: DE38 1101 0100 2430 9065 76</Typography>
      <Typography className={classes.text}>BIC: SOBKDEBBXXX</Typography>
      <Typography variant="h4" component="h2" className={classes.heading}>
        Hinweis auf EU-Streitschlichtung
      </Typography>
      <Typography className={classes.text}>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit, die Sie unter
        http://ec.europa.eu/consumers/odr/ finden. Wir sind bereit, an einem
        außergerichtlichen Schlichtungsverfahren teilzunehmen.
      </Typography>
      <Typography variant="h4" component="h2" className={classes.heading}>
        Urheberrecht
      </Typography>
      <Typography className={classes.text}>
        Alle Rechte vorbehalten. Der gesamte Inhalt und die Struktur dieses
        Internetangebots sind urheberrechtlich geschützt. Alle Informationen und
        Daten (Texte, Grafiken, Animationen, Videos) dürfen ohne unsere
        Zustimmung weder auszugsweise noch in einer anderen Form verwendet oder
        reproduziert werden. Bei entsprechenden Anfragen setzen Sie sich bitte
        mit uns (
        <a
          className={classes.link}
          href="mailto:l_rustemeier@web.de?subject=xHankyy.de%3A+"
        >
          l_rustemeier@web.de
        </a>
        ) in Verbindung.
      </Typography>
      <Typography variant="h4" component="h2" className={classes.heading}>
        Inhaltlich verantwortlich gemäß TDG und §6 MDStV
      </Typography>
      <Typography className={classes.text}>Nis von Kalben</Typography>
    </>
  );
};
