import React, { useLayoutEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Loadouts } from '../../../pages/Loadouts';
import { Merch } from '../../../pages/Merch';
import { Killduel } from '../../../pages/Killduel';
import { Socials } from '../../../pages/Socials';
import { makeStyles } from '@material-ui/core';
import { Impressum } from '../../../pages/Impressum';
import { PrivacyPolicy } from '../../../pages/PrivacyPolicy';

const useStyles = makeStyles(
	({ spacing, breakpoints }) => ({
		root: {
			flex: 1,
			marginTop: spacing(12),
			padding: spacing(7, 4),
			maxWidth: 1440,
			width: '100%',
			margin: 'auto',
			[breakpoints.down('xs')]: {
				marginTop: spacing(10),
				padding: spacing(5, 4),
			},
		},
	}),
	{ name: 'Main' }
);

export const Main: React.FC = () => {
	const { pathname } = useLocation();
	const classes = useStyles();

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<main className={classes.root}>
			<Switch>
				<Route exact path="/">
					<Redirect to={{ pathname: 'loadouts' }} />
				</Route>
				<Route exact path="/loadouts" render={() => <Loadouts />} />
				<Route exact path="/merch" render={() => <Merch />} />
				<Route exact path="/killduell" render={() => <Killduel />} />
				<Route exact path="/socials" render={() => <Socials />} />
				<Route exact path="/impressum" render={() => <Impressum />} />
				<Route exact path="/datenschutz" render={() => <PrivacyPolicy />} />
				<Route>
					<Redirect to={{ pathname: '/loadouts' }} />
				</Route>
			</Switch>
		</main>
	);
};
