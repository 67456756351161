import React from 'react';
import { PageTitle } from '../components/PageTitle';
import {
	Divider,
	List,
	ListItem,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { colors } from '../styles/variables';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(
	({ spacing, breakpoints }) => ({
		root: {
			display: 'flex',
			justifyContent: 'space-between',
			[breakpoints.down('md')]: {
				flexDirection: 'column',
				'& > *': {
					textAlign: 'center',
					margin: 'auto',
					maxWidth: '50% !important',
					marginBottom: spacing(2),
				},
			},
			[breakpoints.down('sm')]: {
				flexDirection: 'column',
				'& > *': {
					maxWidth: '75% !important',
				},
			},
			[breakpoints.down('xs')]: {
				flexDirection: 'column',
				'& > *': {
					maxWidth: '199% !important',
				},
			},
			'& > *': {
				maxWidth: '45%',
			},
		},
		container: {
			margin: spacing(0, 0, 3),
		},
		divider: {
			margin: spacing(2, 0, 3),
			background: colors.turquoise_main,
		},
		subtitle: {
			marginBottom: spacing(1),
		},
		discord: {
			color: colors.discord,
		},
	}),
	{ name: 'Killduel' }
);

export const Killduel: React.FC = () => {
	const classes = useStyles();
	return (
		<>
			<PageTitle title={'Killduell'} />
			<div className={classes.root}>
				<div className={classes.container}>
					<Typography variant="h5" component="h2">
						Killduell Zuschauer gegen Zuschauer, Best-Of-3, Gewinner kriegt 25€
					</Typography>
					<List>
						<Typography variant="h6" component="h3">
							Ablauf:
						</Typography>
						<ListItem>
							<Typography variant="body1">
								Kandidat 1 joined mit Hanky in eine Lobby, Hanky spectated ihn
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Danach joined Kandidat 2 mit Hanky, Hanky spectated wieder
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Wer von beiden Kandidaten mehr Kills macht, bekommt einen Punkt.
								Bei Gleichstand zählt der höhere Schaden
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Dann ist wieder Kandidat 1 dran usw
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Wer 2 Runden für sich entscheidet gewinnt und hat die Chance
								seinen Gewinn zu verdoppeln (auf 50€), wenn er gegen Hanky im
								Killduell gewinnt
							</Typography>
						</ListItem>
					</List>
				</div>
				<div className={classes.container}>
					<Typography variant="h5" component="h2">
						Wie kann man teilnehmen?
					</Typography>
					<List>
						<ListItem>
							<Typography variant="body1">Duo Modus Warzone</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Auf xHankyys{' '}
								<Link to="/socials" className={classes.discord}>
									Discord
								</Link>{' '}
								joinen
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Beim Start der Auswahl (wird im Twitchchat und hier im Channel
								bekannt gegeben) in den Killduell-Auswahl Voicechannel joinen
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Die Kandidaten werden von allen im Voicechannel per Zufall
								ausgelost
							</Typography>
						</ListItem>
					</List>
				</div>
			</div>
			<Divider className={classes.divider} />
			<div>
				<Typography
					className={classes.subtitle}
					variant="h4"
					component="h2"
					align="center"
				>
					!!!Nachdem jemand ausgewählt wurde werden alle einmal gekickt, um
					AFKler zu vermeiden, ihr müsst also für jede Auswahl neu joinen!!!
				</Typography>
				<Typography
					className={classes.subtitle}
					variant="h5"
					component="p"
					align="center"
				>
					Falls ihr ausgewählt wurdet:
				</Typography>
				<Typography variant="body1" align="center">
					Ihr addet den jeweiligen Mod und geht mit ihm in eine Party
				</Typography>
			</div>
		</>
	);
};
