import React from 'react';
import { Layout } from './components/Layout';
import { defaultTheme } from './styles/theme';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

export const App: React.FC = () => {
	return (
		<ThemeProvider theme={defaultTheme}>
			<CssBaseline />
			<Layout />
		</ThemeProvider>
	);
};
