export const colors = {
	black_light: '#202020',
	black_main: '#101010',
	black_dark: '#000000',

	white_light: '#ffffff',
	white_main: '#eeeeee',
	white_dark: '#dddddd',

	gray_light: '#a5a5a5',
	gray_main: '#707070',
	gray_dark: '#303030',

	green_main: '#33cc66',

	turquoise_light: '#33ffcc',
	turquoise_main: '#009999',

	youtube: '#ff0000',
	twitter: '#1da1f2',
	discord: '#7289da',
	//Todo
	instagram: '#ffffff',
	twitch: '#6441a5',
};

export const borderRadius = 5;
